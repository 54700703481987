import React from 'react';
import VideoHeader from '../../common/mobile/VideoHeader';
import img01 from "../../../images/heliofania-01.jpg"

const VideoHeaderPoligonos = (props) => {
    const { pageContext } = props;
    return (
        <VideoHeader
                youtubeurl='https://youtube.com/embed/V4rCfjo3mJc?autoplay=1&controls=0&showinfo=0&autohide=1&rel=0&loop=1&mute=1&playlist=V4rCfjo3mJc&modestbranding=1'
                titleText='poligonos-title'
                htmlText={pageContext.messages["tres-vinos-tres-cepas"] }
                backimg={img01}
            />
    );
};

export default VideoHeaderPoligonos;