import React from 'react';
import Section from '../../common/Section';
import { FormattedMessage } from "react-intl";

const Section2 = (props) => {
    const { pageContext } = props;
    let camino = '..'
	let poslang = '';
    if(pageContext.language === 'en'){
        camino = '../..';
		poslang = "_en";
    }
    return (
        <Section sectionClasses="py-0 text-center position-relative min-vh-100" id="seccion2" childClasses='bg-repeater'>
            <div className="container content-wrapper min-vh-83">
                <div className="row justify-content-around">
                    <div className="min-vh-100 col-md-4 text-start ps-4 texto-con-scroll-mas-altura mt-6 pr-2">
                        <h1 className="title-2"><FormattedMessage id="vinos-que-hablan-de-un-origen" defaultMessage="vinos-que-hablan-de-un-origen" /></h1>
                        <hr />
                        <p className="mt-4 me-3" dangerouslySetInnerHTML={{ __html: pageContext.messages["en-el-anio-dosmiltrece"] }} />
                    </div>
                    <div className="min-vh-100 col-md-8 img_height margin-top-poligono p-0 position-relative">
                        <div className="position-relative">
                            <img src={`${camino}/poligonos/lacelia_cuarteles${poslang}.jpg`} alt="" />
                        </div>
                    </div>
                </div>
            </div>
		</Section>
    );
};

export default Section2;