import React from 'react';
import Section from '../../common/Section';
import img01 from "../../../images/heliofania-01.jpg"
import { FormattedMessage } from "react-intl";

const VideoHeader = (props) => {
    const { pageContext } = props;

    return (
        <Section sectionClasses="py-0 text-center position-relative min-vh-100" childClasses='bg-holder header-video' childStyle={{ backgroundImage: "url(" + img01 + ")" }}>
            <div className="video-wrapper position-absolute w-100 h-100">
                <iframe
                    title="video introduction"
                    frameBorder="0"
                    height="100%"
                    width="100%"
                    src="https://youtube.com/embed/V4rCfjo3mJc?autoplay=1&controls=0&showinfo=0&autohide=1&rel=0&loop=1&mute=1&playlist=V4rCfjo3mJc&modestbranding=1"
                >
                </iframe>
            </div>
            <div className="min-vh-100 content-wrapper d-flex flex-column justify-content-center text-white container">
                <div style={{ "width": "100%", "height": "100vh", "background": "transparent", "position": "absolute", "left": "0", "zIndex": "1" }}></div>
                <h1 style={{ "zIndex": "2" }}><FormattedMessage id="poligonos-title" defaultMessage="poligonos-title" /></h1>
                <p style={{ "zIndex": "2" }} className="col-8 offset-2 text-center" dangerouslySetInnerHTML={{ __html: pageContext.messages["tres-vinos-tres-cepas"] }} />

            </div>
        </Section>
    );
};

export default VideoHeader;