import React from 'react';
import { FormattedMessage } from "react-intl";
import SimpleLocalize from "../../SimpleLocalize";
import Section from '../../common/Section';

const Poligono = (props) => {
    const [itemSeleccionado, setItemSeleccionado] = React.useState('ubicacion');
    const zoomEstados = ['1', '1.5', '2', '2.5'];
    const [zoomEstadoSeleccionado, setzoomEstadoSeleccionado] = React.useState(0);

    const zoomIn = () => {
        console.log(zoomEstadoSeleccionado)
        if (zoomEstadoSeleccionado < zoomEstados.length - 1) {
            setzoomEstadoSeleccionado(estadoAnterior => estadoAnterior + 1)
        }
    }
    const zoomOut = () => {
        console.log(zoomEstadoSeleccionado)
        if (zoomEstadoSeleccionado > 0) {
            setzoomEstadoSeleccionado(estadoAnterior => estadoAnterior - 1)
        }
    }

    const InfoSection = ({ title, content, id }) => {
        return (
            <div className={id}>
                <h3 onClick={() => setItemSeleccionado(id)} className={itemSeleccionado === id ? 'active p-0 pb-2' : 'p-0 pb-2'}>
                    {title}
                </h3>
                <p className={itemSeleccionado === id ? 'd-block' : 'd-none'} dangerouslySetInnerHTML={{ __html: content }}></p>
            </div>
        );
    };

    return (
        <SimpleLocalize {...props}>
            <Section sectionClasses="py-0 text-center position-relative min-vh-100" childClasses='bg-repeater'>
                <div className="min-vh-83 content-wrapper container ">
                    <div className="row justify-content-around">
                        <div className="min-vh-100 col-md-4 text-start mt-6 p-0">
                            <h1 className="title-2" dangerouslySetInnerHTML={{ __html: props.dato.titulo }} />
                            <hr />
                            <div className="text-wrapper-scroll">
                                {props.dato.texto && <p dangerouslySetInnerHTML={{ __html: props.dato.texto }} />}
                                <div className="wine-info col-md-12">
                                    <InfoSection
                                        title={<FormattedMessage id="ubicacion_lower_case" defaultMessage="ubicacion_lower_case" />}
                                        content={props.dato.ubicacion.texto}
                                        id="ubicacion"
                                    />
                                    <InfoSection
                                        title={<FormattedMessage id="electroconductividad-del-cuartel" defaultMessage="electroconductividad-del-cuartel" />}
                                        content={props.dato.electroconductividad.texto}
                                        id="electroconductividad"
                                    />
                                    <InfoSection
                                        title={props.dato.poligono.titulo}
                                        content={props.dato.poligono.texto}
                                        id="poligono"
                                    />
                                    {props.dato.vino && (
                                        <InfoSection
                                            title={props.dato.vino.titulo}
                                            content={props.dato.vino.texto}
                                            id="vino"
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="min-vh-100 col-md-8 img_height margin-top-poligono p-0 position-relative">
                            <img src={props.dato.ubicacion.img} alt="" className={itemSeleccionado == 'ubicacion' ? 'd-block' : 'd-none'} />
                            <img src={props.dato.electroconductividad.img} alt="" className={itemSeleccionado == 'electroconductividad' ? 'd-block' : 'd-none'} />
                            <img src={props.dato.poligono.img} alt="" className={itemSeleccionado == 'poligono' ? 'd-block' : 'd-none'} style={{ 'transform': 'scale(' + zoomEstados[zoomEstadoSeleccionado] + ')' }} />
                            <img src={props.dato.vino.img} alt="" className={itemSeleccionado == 'vino' ? 'd-block' : 'd-none'} />
                            {itemSeleccionado == 'poligono' && <div className='poligono-menu-container'>
                                <button className='zoom-in' onClick={zoomIn}></button>
                                <button className='zoom-out' onClick={zoomOut}></button>
                            </div>}
                        </div>
                    </div>
                </div>
            </Section>
        </SimpleLocalize>
    );
};

export default Poligono;